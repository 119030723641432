<template>
    <div class="Calculator shadow">
        <div class="index-top">
			<div class="index-title">
				<div class="title">利润计算器</div>
			</div>
			<div class="index-prompt">
				<div class="text"></div>
			</div>
		</div>
        <div class="content">
            <el-tabs type="border-card">
                <el-tab-pane label="当前订单毛利盈亏计算器">
                    <div class="One">
                        <div class="box">
                            <div class="One-left">
                                <div class="One-left-num">
                                <li style="margin-left:20px; text-align:center;">请输入销售额</li>
                                <li><input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" id="a1" v-model="one.value1"></li>
                                </div>
                                <span style="margin-top:5px;margin-left:5px;">元</span>
                            </div>
                            <div class="One-left">
                                <div class="One-left-num">
                                <li style="margin-left:20px;">请输入产品成本</li>
                                <li><input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" id="a2" v-model="one.value2"></li>
                                </div>
                                <span style="margin-top:5px;margin-left:5px;">元</span>
                            </div>
                            <div class="One-left">
                                <div class="One-left-num">
                                <li style="margin-left:20px;">请输入发货运费</li>
                                <li><input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" id="a3" v-model="one.value3"></li>
                                </div>
                                <span style="margin-top:5px;margin-left:5px;">元</span>
                            </div>
                            <div class="One-left">
                                <div class="One-left-num">
                                <li style="margin-left:20px;">请输入广告消费</li>
                                <li><input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" id="a4" v-model="one.value4"></li>
                                </div>
                                <span style="margin-top:5px;margin-left:5px;">元</span>
                            </div>
                            <div class="One-left">
                                <div class="One-left-num">
                                    <li style="margin-left:20px;">
                                        请输入发货率
                                        <el-tooltip class="item" effect="dark" content="请输入发货比例" placement="top">
                                            <i class="el-icon-question" style="font-size: 16px;color: #999;"></i>
                                        </el-tooltip>
                                    </li>
                                    <li><input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" id="a5" v-model="one.value5"></li>
                                    <!-- <li style="width:300px;height:100%;">
                                        <select>
                                            <option>1</option>
                                            
                                        </select>
                                    </li> -->
                                </div>
                                <span style="margin-top:5px;margin-left:5px;">%</span>
                            </div>
                            <div class="One-left">
                                <div class="One-left-num">
                                <li style="margin-left:20px;">请输入签收率</li>
                                <li><input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" id="a6" v-model="one.value6"></li>
                                </div>
                                <span style="margin-top:5px;margin-left:5px;">%</span>
                            </div>
                            <el-button type="success" style="margin-top:20px; margin-left:300px;" @click="btn1">立即计算</el-button>
                            <el-button type="warning" style="margin-top:20px;" @click="btn1no">清除</el-button>
                        </div>
                        
                        <div class="One-right">
                            <p style="font-size:18px;color:#606266">计算结果仅供参考</p>
                            <p>提示:结论是当前订单毛利</p>
                            <p>盈亏: <input type="text" style="width:100px;height:30px;text-align:center;" v-model="one.value7" readonly>元</p>
                            <p style="font-size:16px; color:#606266;">计算公式:(销售额-产品成本)*(签收率/100)*(发货率/100)-发货运费-广告费</p>
                            <div class="tubiao">
                                <v-chart class="chart" :option="total"/>
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="每单毛利计算">
                    <div class="One" >
                        <div class="box">
                        <div class="One-left">
                            <div class="One-left-num">
                            <li style="margin-left:20px; text-align:center;">请输入产品销售价</li>
                            <li><input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" v-model="two.value1"></li>
                            </div>
                            <span style="margin-top:5px;margin-left:5px;">元</span>
                        </div>
                        <div class="One-left">
                            <div class="One-left-num">
                            <li style="margin-left:20px;">请输入每单广告成本</li>
                            <li><input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" v-model="two.value2"></li>
                            </div>
                            <span style="margin-top:5px;margin-left:5px;">元</span>
                        </div>
                        <div class="One-left">
                            <div class="One-left-num">
                            <li style="margin-left:20px;">请输入产品成本</li>
                            <li><input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" v-model="two.value3"></li>
                            </div>
                            <span style="margin-top:5px;margin-left:5px;">元</span>
                        </div>
                        <div class="One-left">
                            <div class="One-left-num">
                            <li style="margin-left:20px;">请输入每单运费</li>
                            <li><input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" v-model="two.value4"></li>
                            </div>
                            <span style="margin-top:5px;margin-left:5px;">元</span>
                        </div>
                        <el-button type="success" style="margin-top:20px;margin-left:300px;" @click="btn2">立即计算</el-button>
                        <el-button type="warning" style="margin-top:20px;" @click="btn2no">清除</el-button>
                        </div>
                        <div class="One-right">
                            <p>计算结果仅供参考</p>
                            <p>提示: 结论是当前订单毛利</p>
                            <p>盈亏: <input type="text" style="width:70px;height:30px;text-align:center;" v-model="two.value5" readonly>元</p>
                            <p style="font-size:16px; color:#606266;">计算公式: 产品销售价-(每单广告费+产品成本费+运费)</p>
                            <div class="tubiao">
                                <v-chart class="v-chart" :option="maoli"  style="width:450px;height:300px;" />
                            </div>
                        </div>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="ocpm值计算">
                    <div class="One">
                        <div class="box">
                        <div class="One-left">
                            <div class="One-left-num">
                            <li style="margin-left:20px; text-align:center;">请输入产品销售价</li>
                            <li><input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" v-model="three.value1"></li>
                            </div>
                            <span style="margin-top:5px;margin-left:5px;">元</span>
                        </div>
                        <div class="One-left">
                            <div class="One-left-num">
                            <li style="margin-left:20px;">请输入每单平均货物成本</li>
                            <li><input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" v-model="three.value2"></li>
                            </div>
                            <span style="margin-top:5px;margin-left:5px;">元</span>
                        </div>
                        <div class="One-left">
                            <div class="One-left-num">
                            <li style="margin-left:20px;">请输入每单运费</li>
                            <li><input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" v-model="three.value6"></li>
                            </div>
                            <span style="margin-top:5px;margin-left:5px;">元</span>
                        </div>
                        <div class="One-left">
                            <div class="One-left-num">
                            <li style="margin-left:20px;">请输入发货率</li>
                            <li><input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" v-model="three.value3"></li>
                            </div>
                            <span style="margin-top:5px;margin-left:5px;">%</span>
                        </div>
                        <div class="One-left">
                            <div class="One-left-num">
                            <li style="margin-left:20px;">请输入签收率</li>
                            <li><input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" v-model="three.value4"></li>
                            </div>
                            <span style="margin-top:5px;margin-left:5px;">%</span>
                        </div>
                        <el-button type="success" style="margin-top:20px;margin-left:300px;" @click="btn3">立即计算</el-button>
                        <el-button type="warning" style="margin-top:20px;" @click="btn3no">清除</el-button>
                        </div>
                        <div class="One-right">
                            <p>计算结果仅供参考</p>
                            <p>提示: 结论是当前订单毛利</p>
                            <p>盈亏: <input type="text" style="width:100px;height:30px;text-align:center;" v-model="three.value5" readonly>元</p>
                            <p style="font-size:16px; color:#606266;">计算公式:(产品销售价-每单平均货物成本-每单运费)*(签收率/100)*(发货率/100)</p>
                        </div>
                    </div>
                </el-tab-pane>
            </el-tabs>
            
            <!-- ************************************ROI正推计算系数******************************************** -->
                <!-- <h5>ROI正推计算系数</h5> -->
            <!-- <div v-show = 'menuIndex == 3'>
                <div class="One">
                    <div class="box">
                    <div class="One-left">
                        <div class="One-left-num">
                        <li style="margin-left:20px; text-align:center;">请输入售价</li>
                        <li><input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" v-model="four.value1"></li>
                        </div>
                        <span style="margin-top:5px;margin-left:5px;">元</span>
                    </div>
                    <div class="One-left">
                        <div class="One-left-num">
                        <li style="margin-left:20px;">请输入ROI</li>
                        <li><input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" v-model="four.value2"></li>
                        </div>
                        <span style="margin-top:5px;margin-left:5px;">***</span>
                    </div>
                    <div class="One-left">
                        <div class="One-left-num">
                        <li style="margin-left:20px;color:orange;">广告成本 (自动计算)</li>
                        <li><input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" v-model="four.value3" readonly></li>
                        </div>
                        <span style="margin-top:5px;margin-left:5px;">元</span>
                    </div>
                    <div class="One-left">
                        <div class="One-left-num">
                        <li style="margin-left:20px;">请输入产品成本</li>
                        <li><input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" v-model="four.value4"></li>
                        </div>
                        <span style="margin-top:5px;margin-left:5px;">元</span>
                    </div>
                    <div class="One-left">
                        <div class="One-left-num">
                        <li style="margin-left:20px;color:orange;">总计成本 (自动计算)</li>
                        <li><input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" v-model="four.value5" readonly></li>
                        </div>
                        <span style="margin-top:5px;margin-left:5px;">元</span>
                    </div>
                    <div class="One-left">
                        <div class="One-left-num">
                        <li style="margin-left:20px;color:orange;">利润 (自动计算)</li>
                        <li><input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" v-model="four.value6" readonly></li>
                        </div>
                        <span style="margin-top:5px;margin-left:5px;">元</span>
                    </div>
                    <el-button type="success" style="margin-top:20px;" @click="btn4">立即计算</el-button>
                    <el-button type="warning" style="margin-top:20px;" @click="btn4no">清除</el-button>
                    </div>
                </div>
            </div> -->
            <!-- ************************************ROI反推计算系数******************************************** -->
                <!-- <h5>ROI反推计算系数</h5> -->
            <!-- <div v-show = 'menuIndex == 4'>
                <div class="One">
                    <div class="box">
                    <div class="One-left">
                        <div class="One-left-num">
                        <li style="margin-left:20px; text-align:center;">请输入售价</li>
                        <li><input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" v-model="five.value1"></li>
                        </div>
                        <span style="margin-top:5px;margin-left:5px;">元</span>
                    </div>
                    <div class="One-left">
                        <div class="One-left-num">
                        <li style="margin-left:20px;">请输入广告成本</li>
                        <li><input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" v-model="five.value2"></li>
                        </div>
                        <span style="margin-top:5px;margin-left:5px;">元</span>
                    </div>
                    <div class="One-left">
                        <div class="One-left-num">
                        <li style="margin-left:20px; color:orange;">ROI(自动计算)</li>
                        <li><input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" v-model="five.value3" readonly></li>
                        </div>
                        <span style="margin-top:5px;margin-left:5px;">***</span>
                    </div>
                    <div class="One-left">
                        <div class="One-left-num">
                        <li style="margin-left:20px;">请输入产品成本</li>
                        <li><input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" v-model="five.value4"></li>
                        </div>
                        <span style="margin-top:5px;margin-left:5px;">元</span>
                    </div>
                    <div class="One-left">
                        <div class="One-left-num">
                        <li style="margin-left:20px;color:orange;">总计成本 (自动计算)</li>
                        <li><input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" v-model="five.value5" readonly></li>
                        </div>
                        <span style="margin-top:5px;margin-left:5px;">元</span>
                    </div>
                    <div class="One-left">
                        <div class="One-left-num">
                        <li style="margin-left:20px; color:orange;">利润 (自动计算)</li>
                        <li><input type="text" oninput="value=value.replace(/[^\d\.]/g,'')" v-model="five.value6" readonly></li>
                        </div>
                        <span style="margin-top:5px;margin-left:5px;">元</span>
                    </div>
                    <el-button type="success" style="margin-top:20px;" @click="btn5">立即计算</el-button>
                    <el-button type="warning" style="margin-top:20px;" @click="btn5no">清除</el-button>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</template>
<script>

import "echarts";
// import "echarts/lib/chart/pie";
// import "echarts/lib/component/tooltip";
export default {
    data(){
        return {
            openhuan:{
                xAxis: {
                    type: 'category',
                    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                },
                yAxis: {
                    type: 'value'
                },
                series: [{
                    data: [150, 230, 224, 218, 135, 147, 260],
                    type: 'line'
                }]
            },
            total:{
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c} ({d}%)'
                },
                series:{
                    name: '当前订单毛利盈亏计算器',
                    type: 'pie',
                    data: [
                        {value: 0, name: '销售额'},
                        {value: 0, name: '产品成本'},
                        {value: 0, name: '发货运费'},
                        {value: 0, name: '广告消费'},
                    ],
                }
            },
            maoli:{
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c} ({d}%)'
                },
                series:{
                    name: '每单毛利计算',
                    type: 'pie',
                    data: [
                        {value: 0, name: '产品销售价'},
                        {value: 0, name: '每单广告成本'},
                        {value: 0, name: '产品成本'},
                        {value: 0, name: '每单运费'},
                    ],
                }
            },
            menuIndex:0,
            one:{
                value1:"",
                value2:"",
                value3:"",
                value4:"",
                value5:"",
                value6:"",
                value7:"0"
                
            },
            two:{
                value1:"",
                value2:"",
                value3:"",
                value4:"",
                value5:"0",
            },
            three:{
                value1:"",
                value2:"",
                value3:"",
                value4:"",
                value6:"",
                value5:"0",
            },
            four:{
                value1:"",
                value2:"",
                value3:"",
                value4:"",
                value5:"",
                value6:"",
            },
            five:{
                value1:"",
                value2:"",
                value3:"",
                value4:"",
                value5:"",
                value6:"",
            },
            navs:[
                {name:"当前订单毛利盈亏计算器"},
                {name:"每单毛利计算"},
                {name:"OCPM值计算"},
                // {name:"ROI正推计算系数"},
                // {name:"ROI反推计算系数"}
            ],
        };
       
    },
    methods:{
     menuShow (index) {
         this.menuIndex = index
        //  console.log(this.menuIndex)
     },
     //当前订单毛利盈亏计算器:(计算公式:(销售额-产品成本)*(签收率/100)*(发货率/100)-发货运费-广告费)
     btn1(){
         this.total.series.data = [];
         const x = this.one.value1; //销售额
         const c = this.one.value2; //产品成本
         const q = this.one.value6; //签收率
         const f = this.one.value5; //发货率
         const y = this.one.value3; //发货运费
         const g = this.one.value4; //广告费
        //  const j = this.one.value7; //盈亏
        if(this.one.value1 && this.one.value2 && this.one.value3 && this.one.value4){
            this.one.value7 =((x-c)*(q/100)*(f/100)-y-g).toFixed(2);
            this.total.series.data.push({
                value:this.one.value1,
                name:'销售额(元)'
            })
            this.total.series.data.push({
                value:this.one.value2,
                name:'产品成本(元)'
            })
            this.total.series.data.push({
                value:this.one.value3,
                name:'发货运费(元)'
            })
            this.total.series.data.push({
                value:this.one.value4,
                name:'广告费(元)'
            })
        }else{
            this.$message('请输入所有项数值');
        }
        
        console.log(this.total.series.data)
     },
     btn1no(){
         this.one.value1=""; //销售额
         this.one.value2=""; //产品成本
         this.one.value6=""; //签收率
         this.one.value5=""; //发货率
         this.one.value3=""; //发货运费
         this.one.value4=""; //广告费
         this.total.series.data = [
            {value: 0, name: '销售额(元)'},
            {value: 0, name: '产品成本(元)'},
            {value: 0, name: '发货运费(元)'},
            {value: 0, name: '广告消费(元)'},
         ]
     },
     //每单毛利计算
     btn2(){
         this.maoli.series.data = [];
         const x = this.two.value1; //销售价格
         const g = this.two.value2; //每单广告成本
         const c = this.two.value3; //每单产品成本
         const y = this.two.value4; //每单运费
        if(this.two.value1 && this.two.value2 && this.two.value3 && this.two.value4){
            this.two.value5 = (x-g-c-y).toFixed(2);
            this.maoli.series.data.push({
                value:this.two.value1,
                name:'销售价格(元)'
            })
            this.maoli.series.data.push({
                value:this.two.value2,
                name:'广告成本(元)'
            })
            this.maoli.series.data.push({
                value:this.two.value3,
                name:'产品成本(元)'
            })
            this.maoli.series.data.push({
                value:this.two.value4,
                name:'每单运费(元)'
            })
        }else{
            this.$message('请输入所有项数值');
        }
         
     },
     btn2no(){
          this.two.value1=""; //销售价格
          this.two.value2=""; //每单广告成本
          this.two.value3=""; //每单产品成本
          this.two.value4=""; //每单运费
          this.maoli.series.data = [
            {value: 0, name: '产品销售价'},
            {value: 0, name: '每单广告成本'},
            {value: 0, name: '产品成本'},
            {value: 0, name: '每单运费'},
         ]
     },
     //ocpm值计算
     btn3(){
         const y = this.three.value6; //运费
         const x = this.three.value1; //销售价格
         const c = this.three.value2; //每单货物成本
         const f = this.three.value3; //每单发货率
         const q = this.three.value4; //签收率
        if(this.three.value1 && this.three.value2 && this.three.value3 && this.three.value4){
            this.three.value5 = ((x-c-y)*(q/100)*(f/100)).toFixed(2);
        }else{
            this.$message('请输入所有项数值');
        }
     },
     btn3no(){
          this.three.value1=""; 
          this.three.value2=""; 
          this.three.value3=""; 
          this.three.value4=""; 
          this.three.value6="";
     },
     //roi正推计算系数
     btn4(){
         const s = this.four.value1; //售价
         const r = this.four.value2; //roi
         const c = this.four.value4; //产品成
        
        if(this.four.value1 && this.four.value2 && this.four.value4){
             this.four.value3 = (s/r).toFixed(2);//广告成本自动计算
            this.four.value5 = (Number(c)+(s/r)).toFixed(2);//总计成本自动计算
            this.four.value6 = (s-(Number(c)+(s/r))).toFixed(2);//利润
        }else{
            this.$message('请输入所有项数值');
        }
     },
     btn4no(){
          this.four.value1=""; 
          this.four.value2=""; 
          this.four.value3=""; 
          this.four.value4=""; 
          this.four.value5="";
          this.four.value6="";
     },
     //roi反推计算系数
     btn5(){
         const s = this.five.value1; //售价
         const g = this.five.value2; //广告成本
        //  const g = this.five.value3; //roi自动计算
         const c = this.five.value4; //产品成本
        //  const z = this.five.value5; //总计成本自动计算
        //  const l = this.five.value6; //利润自动计算
        if(this.five.value1 && this.five.value2 && this.five.value4){
            this.five.value3 = (s/g).toFixed(2);//roi自动计算
            this.five.value5 = (Number(c)+Number(g)).toFixed(2);//总计成本自动计算
            this.five.value6 = (s-(Number(c)+Number(g))).toFixed(2);//利润成本自动计算
        }else{
            this.$message('请输入所有项数值');
        }
     },
     btn5no(){
          this.five.value1=""; 
          this.five.value2=""; 
          this.five.value3=""; 
          this.five.value4=""; 
          this.five.value5="";
          this.five.value6="";
     },
  }


}

</script>
<style lang="scss" secped>
.tubiao{
    .chart{
        width: 420px;
        height: 300px;
    }
}
    
    .tops{
        width:100%;
        height:60px;
        // color:#fff;
        // margin-left:10px;
        // display: flex;
        justify-content: space-around;
        align-items: center;
        background:#fff;
        // border-top:2px solid #ccc;
        overflow: hidden;
        padding-top: 8px;
    }
    .tops li{
        float: left;
        margin-left: 20px;
        // width:18%;
        // height:45px;
        // text-align:center;
        // line-height: 45px;
        // font-size: 16px;
        // color:#606266;
        // // background:rgb(127, 140, 196);
        // border:1px solid #ccc;
    }
    .content{
        width:100%;
        height:80%;
        margin-top:20px;
        // margin-left:10px;
        // background:rgb(213, 226, 241);
    }
    .One{
        width:100%;
        height:100%;
        display: flex;
        justify-content: space-around;
    }
    .box{
        width:510px;
        height:600px;
    }
    .One-left{
        width:510px;
        display: flex;
        margin-top:0px;
        padding:10px;
        height:70px;
        align-items: center;
        input{
            padding: 0 10px;
        }
       
    }
    .One-left-num{
        display: flex;
        width:500px;
        height:50px;
        font-size: 16px;
        color:#606266;
        background:rgb(253, 254, 255);
        align-items: center;
        justify-content:space-between;
    }
    .One-left span{
        font-size: 16px;
        color:#606266;
        
    }
    .One-right{
        // width:510px;
        // height:300px;
        font-size: 16px;
        color:#606266;
        padding-left:30px;
        // background:rgb(54, 83, 107);
    }
    input{
        width:300px;
        height:50px;
        outline:none;
        border:none;
        border:1px solid #ccc;
        text-align: center;
    }
    select{
        width:100%;
        height:48px;
        margin-top:1px;
        border: 0px;
        outline: 1px solid rgb(204,204,204);
    }
    .Calculator h5{
        width:300px;
        height:50px;
        margin-top:0px;
        line-height: 50px;
        text-align: center;
        color:rgb(230, 164, 42);
        font-size:16px;
        // background:rgb(59, 66, 99);
        // border:1px solid red;
    }
    // .tops li:hover{
    //     background:#005aff;
    //     border: 1px solid #005aff;
    //     color:#fff; 
    //     -webkit-transition: .5s;
    //     transition: .5s;
    // }
    // .tops li{
    //     -webkit-transition: .5s;
    //     transition: .5s;
    // }
    .tubiao{
        height: 300px;
        text-align: center;;
        width: 420px;
    }
</style>